/*--------------------*/
/*    GLOBAL RESET    */
/*--------------------*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  /*
    1rem = 10px
  */
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: "Space Grotesk", sans-serif;
  background-color: #151515;
  position: relative;
  z-index: 5;
  overflow-x: hidden;
}

/*--------------------*/
/*       HEADER       */
/*--------------------*/

.header-section {
  display: flex;
  justify-content: space-between;
  padding: 3.9rem 16.5rem 0 16.5rem;
  align-items: center;
}

.header-name {
  color: #fff;
  text-shadow: 0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.2rem;
  letter-spacing: -0.0444rem;
}

.social-icons {
  display: flex;
  gap: 3.2rem;
  margin-right: 2.95rem;
  align-items: center;
}

.social-icon-outer {
  cursor: pointer;
  height: 2.4rem;
}

.social-icon-outer:hover .social-icon {
  fill: #4ee1a0;
  transition: all 0.3s;
}

/*--------------------*/
/*       INTRO        */
/*--------------------*/

.intro-section {
  padding: 0 16.5rem;
}

.intro-text-container {
  margin-top: 12.7rem;
  max-width: 70.6rem;
}

.heading-primary {
  font-size: 7.8rem;
  font-weight: 700;
  line-height: 8.8rem;
  letter-spacing: -2.5px;
  color: #fff;
}

.name-highlight {
  text-decoration: underline;
  text-decoration-color: #4ee1a0;
}

.intro-description {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.8rem;
  color: #d9d9d9;
  margin-top: 4.3rem;
}

.contact-link {
  display: inline-block;
  transition: all 0.3s;
}

.margin-top {
  margin-top: 6.6rem;
}

.contact-link:link,
.contact-link:visited {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.6rem;
  letter-spacing: 0.2286rem;
  color: #fff;
  text-decoration-color: #4ee1a0;
  text-underline-offset: 1rem;
}

.contact-link:hover,
.contact-link:active {
  color: #4ee1a0;
}

.developer-img {
  position: absolute;
  top: 0;
  right: 16.5rem;
  width: 44.5rem;
  height: 72rem;
  z-index: -1;
  background-image: url("../public/assets/portfolio-img-desktop.webp");
  background-position: center;
  background-size: cover;
}

.rings-pattern-intro {
  position: absolute;
  left: -12rem;
  top: 13.3rem;
  z-index: -2;
}

.circle-pattern-intro {
  position: absolute;
  top: 52.1rem;
  right: 54.5rem;
}

.rule {
  height: 1px;
  color: #fff;
  margin-top: 21.9rem;
  padding: 0 16.5rem;
}

/*--------------------*/
/*       SKILLS       */
/*--------------------*/

.skills-section {
  padding: 0 16.5rem;
  margin-top: 1.4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 3rem;
  column-gap: 5.8rem;
  position: relative;
  padding-bottom: 11rem;
}

.skill {
  margin-top: 5.8rem;
}

.heading-secondary {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 5.6rem;
  letter-spacing: -1.5px;
}

.skill-experience {
  color: #d9d9d9;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.8rem;
  margin-top: 1.4rem;
}

.rings-pattern-skill {
  position: absolute;
  right: -30rem;
  top: 25.2rem;
}

/*--------------------*/
/*     PROJECTS       */
/*--------------------*/
.projects-section {
  padding: 0 16.5rem;
  position: relative;
  margin-bottom: 13.9rem;
}

.projects-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.1rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 6.9rem;
  margin-top: 6.9rem;
  column-gap: 2.4rem;
}

.project {
  position: relative;
  cursor: pointer;
}

.project-img {
  width: 100%;
}

.overlay {
  background: rgba(0, 0, 0, 0.75);
  height: 39.5rem;
  width: 54.6rem;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s;
}

.view {
  display: flex;
  position: absolute;
  flex-direction: column;
  left: 50%;
  top: 35%;
  align-items: center;
  row-gap: 4.8rem;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}

.project:hover .overlay {
  opacity: 1;
}

.project:hover .view {
  opacity: 1;
}

.heading-tertiary {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 3.2rem;
  text-transform: uppercase;
  margin-top: 2rem;
}

.tech-list {
  display: flex;
  gap: 1.8rem;
  margin-top: 0.7rem;
}

.tech-name {
  color: #d9d9d9;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.8rem;
  text-transform: uppercase;
}

/*--------------------*/
/*   CALL TO ACTION   */
/*--------------------*/

.cta-section {
  padding: 8.4rem 16.5rem 0rem 16.5rem;
  background-color: #242424;
  position: relative;
}

.cta-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
}

.cta-contact {
}

.cta-description {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.8rem;
  color: #d9d9d9;
  margin-top: 3.6rem;
}

.cta-form {
  justify-self: end;
  display: flex;
  flex-direction: column;
}

.text-field {
  display: block;
  background-color: #242424;
  border: none;
  border-bottom: 1px solid #fff;
  width: 44.5rem;
  padding-left: 2.4rem;
  padding-bottom: 1.6rem;
  color: #fff;
}

.margin-top-sm {
  margin-top: 3.2rem;
}

.text-area {
  display: block;
  background-color: #242424;
  border: none;
  border-bottom: 1px solid #fff;
  width: 44.5rem;
  padding-left: 2.4rem;
  padding-bottom: 1.6rem;
  height: 10.7rem;
  color: #fff;
  resize: none;
}

.text-area:focus,
.text-field:focus {
  outline: none;
  border-bottom: 1px solid #4ee1a0;
}

.field-error {
  border-bottom: 1px solid #ff6f5b;
}

.error-text {
  color: #ff6f5b;
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: -0.167px;
  text-align: right;
}

.cta-form a {
  align-self: flex-end;
}

.rings-pattern-cta {
  position: absolute;
  top: 32.7rem;
  left: -20.5rem;
}

.rule-cta {
  margin-top: 9.2rem;
  padding: 0 1.6rem;
  height: 1px;
  color: #fff;
}

.form-btn {
  background: none;
  border: none;
  padding: none;
}

/*--------------------*/
/*       FOOTER       */
/*--------------------*/

.footer-section {
  display: flex;
  justify-content: space-between;
  padding: 4.7rem 16.5rem 9.2rem 16.5rem;
  align-items: center;
  background-color: #242424;
}

.footer-name {
  color: #fff;
  text-shadow: 0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.2rem;
  letter-spacing: -0.0444rem;
}
