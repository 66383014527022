/*--------------------*/
/*       Tablets      */
/*--------------------*/


@media (max-width : 54em) {
    /*--------------------*/
    /*       HEADER       */
    /*--------------------*/
    .header-section {
        padding: 2.9rem 3.0rem 0 3.0rem;
    }

    .social-icons {
        margin-right: 0;
    }

    /*--------------------*/
    /*       INTRO        */
    /*--------------------*/
    .intro-section {
        padding: 0 3.2rem;
    }

    .rings-pattern-intro {
        left: -25rem;
        top : 8.6rem
    }

    .intro-text-container {
        margin-top: 9.0rem;
        width: 43rem;
    }

    .heading-primary {
        font-size: 7.2rem;
        line-height: 7.2rem;
        letter-spacing: -2.045px;
    }

    .intro-description {
        margin-top: 6.0rem;
    }

    .contact-link {
        margin-top: 3.4rem;
    }

    .circle-pattern-intro {
        top : 47.1rem;
        right: -6rem;
    }

    .developer-img {
        right: 0;
        width: 32.2rem;
        height: 60rem;
        background-image: url('../public/assets/portfolio-img-desktop.webp');
        background-position: center;
        background-size: cover;
    }

    .rule {
        height: 1px;
        color: #fff;
        margin-top : 6rem;
        padding: 0 3rem;
    }

    /*--------------------*/
    /*       SKILLS       */
    /*--------------------*/

    .skills-section {
        padding: 0 3.2rem;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0;
        row-gap: 0rem;
        padding-bottom: 10rem;
    }

    .skill {
        margin-top: 5.2rem;
    }

    .rings-pattern-skill {
        top : 38.6rem;
        right : -35rem;
    }

    /*--------------------*/
    /*     PROJECTS       */
    /*--------------------*/

    .projects-section {
        padding: 0 2.9rem;
        position: relative;
        margin-bottom: 10rem;
    }

    .projects-header {
        margin-bottom: 0;
    }

    .projects-header .contact-link {
        margin-top: 0;
    }

    .projects-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 6rem;
        column-gap: 2rem;
        margin-top : 6rem;
    }

    .project-img {
        height: 25.3rem;
        width: 34.5rem;
        width: 100%;
    }

    /* Make the view div reappaer*/
    .view {
        opacity: 1;
        position: static;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        column-gap: 3.2rem;
        transform: translate(0%, 0%);
        row-gap: 0;
    }

    .view .contact-link {
        margin-top: 2rem;
    }


    /*--------------------*/
    /*        CTA         */
    /*--------------------*/

    .cta-section {
        padding: 6rem 3rem 9.2rem 3rem;
        background-color: #242424;
        position: relative;
    }

    .cta-grid {
        grid-template-columns: 1fr;
        justify-content: center;
    }

    .cta-contact {
        justify-self: center;
    }

    .cta-heading {
        text-align: center;
    }

    .cta-description {
        text-align: center;
    }

    .cta-form {
        justify-self: center;
        display: flex;
        flex-direction: column;
        margin-top: 4.8rem;
    }

    .rings-pattern-cta {
        top : 54.7rem;
        left: -36.8rem;
    }

    .rule-cta {
        margin-top : 9.2rem;
        padding: 0 3rem;
        height: 1px;
        color: #fff;
    }

    /*--------------------*/
    /*       FOOTER       */
    /*--------------------*/
    .footer-section {
        padding: 3rem 3.0rem 4.3rem 3.0rem;
    }
}




/*--------------------*/
/*   Mobile Phones    */
/*--------------------*/


@media (max-width : 34em) {
    /*--------------------*/
    /*       HEADER       */
    /*--------------------*/
    .header-section {
        padding: 2.0rem auto 0 auto;
        flex-direction: column;
    }

    .header-name {
        text-shadow: none;
        font-size: 2.4rem;
        letter-spacing: -0.0333rem;
    }

    .social-icons {
        gap : 2.56rem;
        margin-top: 2.0rem;
    }

    .social-icon {
        height: 1.92rem;
    }

    /*--------------------*/
    /*       INTRO        */
    /*--------------------*/

    .intro-section {
        padding: 0 1.6rem;
    }

    .rings-pattern-intro {
        left: -28rem;
        top : 12.4rem
    }

    .circle-pattern-intro {
        top: 25.4rem;
        right: -8rem;
    }

    .developer-img {
        top : 0;
        width: 17.4rem;
        height: 38.3rem;
        background-image: url('../public/assets/portfolio-img-desktop.webp');
        background-position: center;
        background-size: cover;
        left : 50%;
        transform: translate(-50%, 0);
    }

    .intro-text-container {
        margin-top: 33.1rem;
        text-align: center;
        width: 100%;
    }

    .heading-primary {
        font-size: 4rem;
        line-height: 4rem;
        letter-spacing: -1.136px;
    }

    .heading-intro {

    }

    .intro-description {
        margin-top: 2.0rem;
        font-size: 1.6rem;
        line-height: 2.6rem;
        text-align: center;
        justify-self: center;
    }

    .rule {
        height: 1px;
        color: #fff;
        margin-top : 8rem;
        padding: 0 1.6rem;
    }

    /*--------------------*/
    /*       SKILLS       */
    /*--------------------*/

    .skills-section {
        margin: 0 1.6rem;
        grid-template-columns: 1fr;
        align-items: center;
        text-align: center;
        padding-bottom: 4rem;
        border-bottom: 1px solid #fff;

    }

    .skill {
        margin-top: 4rem;
    }

    .rings-pattern-skill {
        top : 60rem;
        right : -35rem;
    }

    .heading-secondary{
        font-size: 3.2rem;
        line-height: 4rem;
        letter-spacing: -1px;
    }

    .skill-experience {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.6rem;
        margin-top: 0;
    }

    /*--------------------*/
    /*     PROJECTS       */
    /*--------------------*/

    .projects-section {
        padding: 0 1.5rem;
        position: relative;
        margin-bottom: 8rem;
    }

    .projects-grid {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 4rem;
        margin-top : 4rem;
        align-items: center;
    }
    
    .projects-header {
        margin-top: 8rem;
    }

    .projects-header .contact-link{
        margin-top: 0;
    }

    .project-img {
        width: 100%;
        height: 90%;
    }

    /*--------------------*/
    /*        CTA         */
    /*--------------------*/

    .cta-section {
        padding: 6rem 1.6rem 8.7rem 1.6rem;
        background-color: #242424;
        position: relative;
    }

    .rings-pattern-cta {
        position: absolute;
        top: 43.6rem;
        left: -34.3rem;
    }

    .rule-cta {
        margin-top : 8.7rem;
        padding: 0 1.6rem;
        height: 1px;
        color: #fff;
    }

    .text-field {
        width: 34.3rem;
    }

    .text-area {
        width: 34.3rem;
    }

    /*--------------------*/
    /*       FOOTER       */
    /*--------------------*/
    .footer-section {
        padding: 3.9rem auto 0 auto;
        flex-direction: column;
    }
}